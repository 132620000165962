import React from 'react'
import { Link, useParams } from 'react-router-dom'

const AbsatAppsListItem = ({app}) => {
    const _params = useParams();
    const _token = localStorage.getItem('token');
    const _urlNavigate = app.status ==='done' ? `${app.baseUrl}/${_params.tenant}/token/${_token}` : '#';

    return (
        <Link to={`${_urlNavigate}`}>
            <div className={`h-40 overflow-hidden relative rounded-xl border py-5  flex flex-col  items-center transition duration-0 hover:duration-500 hover:transition-all  ${app.status ==='loading' ? 'app-loading border-slate-700' : 'app-active border-yellow-950 hover:font-bold hover:p-2 '}`}>
                <img src={`/assets/icons/${app?.icon}`} className={`  w-16  `} />
                <div className='absolute z-20 end-0 bottom-0 w-full bg-slate-800 secondary-color rounded-b-xl  h-16 border-t border-slate-600 flex items-center justify-center  text-slate-100 cursor-default'>{app.titleAr}</div>
            </div>
        </Link>
    )
}

export default AbsatAppsListItem