import React, { useEffect } from 'react'
import { AbsatAppsList } from '../components/absat-apps/AbsatAppsList'
import { useTenant } from '../hooks';

const DashBoard = () => {
    const {tenant} = useTenant();

    // const [t] = useTranslation('global')
    // const {currentSettings,currentTenantId,currentCompanyId,currentBranchId} = useCurrent();
    // let salesCurrentYear = 0;
    // const date = new Date();
    // const currentMonth = date.getMonth() + 1;
    // const currentYeay = date.getFullYear();

    useEffect(() =>{
        if(tenant){
            console.log(tenant)

        }
    },[tenant])

    return (
        <>
            {
                tenant.companies &&
                    <div className='h-full flex flex-col justify-center items-center'>
                        <div className='mb-5 border-b pb-3 border-slate-800'>
                            <div className='z-20 flex justify-center'><img src={ tenant?.companies[0].logo ?  tenant.companies[0].logo  : '/logo.webp'} className='w-4/6' /></div>
                            <div className='z-30 mt-3 text-center text-4xl text-slate-300'>تطبيقات <span className='secondary-color'>أبسط</span> أعمال</div>
                            <div className='z-30 text-center  text-slate-400  '>نظام إدارة الاعمال بسطناها عليك في كل شي</div>

                            {/* <div className='text-4xl p-7 text-slate-300'>Applications <span className='secondary-color'>Absat</span> Aamal</div> */}
                        </div>
                        <AbsatAppsList/>
                        
                    </div>
            }
        </>
    )
}

export default DashBoard