import React from 'react'
import { APPS } from '../../constants/AbsatApps.js'
import AbsatAppsListItem from './AbsatAppsListItem.jsx'

export const AbsatAppsList = () => {
    return (
        <div className='grid grid-cols-5 gap-7 w-5/6 '>
            {
                APPS.map(app => (
                    <AbsatAppsListItem key={app.name} app={app}/>
                ))
            }
        </div>
    )
}
